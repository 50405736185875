import * as React from 'react';
import { FC } from 'react';

const SectionRecruiting: FC<{ recruiting: any }> = ({ recruiting }) => {
  return (
    <section className={` section section-recruiting-video`}>
      <div className=" columns is-centered  ">
        <div className="column is-10 is-12-mobile  no-pad intro_block">
          {recruiting.title && (
            <h2 className="title title-medium section-title text-black has-text-centered">
              {recruiting.title}
            </h2>
          )}
          {recruiting.text && (
            <p className="has-text-centered text-big">{recruiting.text}</p>
          )}
        </div>
      </div>
      <div className=" columns is-centered">
        <div className="column is-12">
          {recruiting.video && (
            <div className={` video is-centered text-centered`}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/pw-ozfvHmFQ"
                title="how start with shore"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SectionRecruiting;
