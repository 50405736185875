/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-danger */
import * as React from 'react';
import { FC, useState, useEffect } from 'react';

import Button from '../ui/button';
import { isMobileOnly, isMobile } from 'react-device-detect';
import { Image } from '../ui/image';


const OpenJobs: FC<{
  title: string;
  contact: any;
}> = ({ title, contact }) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetch('https://career-shore.kenjo.io/api/company-career-site-db/public/career-shore')
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          console.log(result);
          setJobs(result.activePositions);
        },
        // eslint-disable-next-line no-shadow
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);
  return (
    <section className={` section jobs-section `} id="jobs">
      <div className="container column is-9 is-12-mobile  is-12-tablet">
        <div className="columns is-centered">
          <div className="column is-8 ">
            <h2 className="title title-large has-text-centered text-black">
              {title}
            </h2>
          </div>
        </div>
      </div>
      <div
        id="kenjoCareerSite"
        className="container column is-9 is-12-mobile  is-12-tablet"
      >
        <div className="job-row">

          <table className="table is-fullwidth jobs_table">
            {jobs && Object.keys(jobs).map((key) => {
              const job = jobs[key];
              console.log(job);
              return  isMobileOnly ? 
              ( <tr key={key} >
                <td className="text-black  ">
                  {job.jobTitle}<br/>
                  {/* job.officeName.replace('Office', '') */}
                  {job.positionType}<br/>
                  <Button to={"https://career-shore.kenjo.io/" + job.customUrl} link className="inline  text-black">
                  {contact.apply_btn ?  contact.apply_btn : 'apply'}   <Image
                      name="angle-right.svg"
                      alt="angle-left icon"
                      className="inline-icon angle inline"
                    />
                  </Button>
                </td>
         
              </tr>)
              :
               (

                <tr key={key} >
                  <td className="text-black  ">
                    {job.jobTitle}
                  </td>
                  <td className="text-black" style={{width: '110px', display: 'none'}}>
                    {job.officeName.replace('Office', '')}
                  </td>
                  <td className="text-black " style={{width: '110px'}}>
                    {job.positionType}
                  </td>
                  <td className="text-black " style={{width: '110px'}}>
                  <Button to={"https://career-shore.kenjo.io/" + job.customUrl} link className="inline text-black">
                    {contact.apply_btn ?  contact.apply_btn : 'apply'}  <Image
                      name="angle-right.svg"
                      alt="angle-left icon"
                      className="inline-icon angle inline"
                    />
                  </Button>
                  </td>
                </tr>

              );


            })}
          </table>
        </div>
      </div>
      <div className="container column is-9 is-12-mobile is-12-tablet contact-area">
        <div className="columns is-centered">
          <div className="column is-8 has-text-centered">
            <p className=" ">
              <b>{contact.title}</b>
            </p>
            <p className=" ">
            <a
                href={`mailto:${contact.email}`}
                className="  mail-contact text-black"
              >
                {contact.email}
              </a>
              <a
                href={`tel:${contact.dialNo}`}
                className="text-black  phone-contact"
              >
                {contact.displayNo}
              </a>
             
            </p>
            <Button className="mt-5" to="https://www.linkedin.com/company/shore-gmbh/">
              {contact.button}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OpenJobs;
