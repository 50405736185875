import * as React from 'react';
import { FC } from 'react';

const SectionFacts: FC<{ columns: any }> = ({ columns }) => {
  return (
    <section className={` section section-facts`}>
      <div className=" columns is-centered">
        {Object.keys(columns).map((key) => {
          const { text, title } = columns[key];
          return (
            <div
              key={key}
              className="column is-4 is-12-mobile is-4-tablet has-text-centered"
            >
              <h3 className="has-text-centered text-black title title-extra-big ">
                {title}
              </h3>
              <p className="has-text-centered fact-text">{text}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default SectionFacts;
