/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore

import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { isMobileOnly } from 'react-device-detect';
import { LocalizedPageContext, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { HeroText } from '../components/partials/common/hero';
import OpenJobs from '../components/sections/jobs';
import SectionFacts from '../components/sections/facts';
import SectionRecruiting from '../components/sections/recruiting';
import { ImageGallery } from '../components/ui/imageGallery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Page: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const localKey = `${pageContext.key}`;
  const { t } = useTranslation(localKey);
  const { lang, alternateUrls } = pageContext;
  const columns: IColumnList = t('workingAtShore.facts', {
    returnObjects: true,
  });
  const recruiting: IColumnList = t('recruiting', {
    returnObjects: true,
  });
  const contactData: IColumnList = t('contact', {
    returnObjects: true,
  });
  const hero: any = t('hero', {
    returnObjects: true,
  });

  const aboutShore: any = t('aboutShore', {
    returnObjects: true,
  });
  const sliderSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1800,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: false,
    variableWidth: true,
  };

  return (
    <Layout pageContext={pageContext}>
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroText
        title={hero.title}
        text={hero.text}
        ctaUrl={hero.ctaUrl}
        ctaText={hero.ctaText}
      />
      {!isMobileOnly && (
        <section className="image_row_fw hidden_mobile">
          <div className="overfloater">
            <ImageGallery
              name="shore_website_about_jobs_hero_01a"
              className="image_float_row portrait"
              width="420"
              height="580"
              alt="mode image 1"
            />
            <ImageGallery
              name="shore_website_about_jobs_hero_01b"
              className="image_float_row portrait"
              width="420"
              height="580"
              alt="mode image 1"
            />
            <ImageGallery
              name="shore_website_about_jobs_hero_01c"
              className="image_float_row portrait"
              width="420"
              height="580"
              alt="mode image 1"
            />
          </div>
        </section>
      )}
      {isMobileOnly && (
        <Slider
          {...sliderSettings}
          className={` main-slider image_row_slider portrait`}
        >
          <ImageGallery
            name="shore_website_about_jobs_hero_01a"
            className="image_float_row portrait"
            width="420"
            height="580"
            alt="mode image 1"
          />
          <ImageGallery
            name="shore_website_about_jobs_hero_01b"
            className="image_float_row portrait"
            width="420"
            height="580"
            alt="mode image 1"
          />
          <ImageGallery
            name="shore_website_about_jobs_hero_01c"
            className="image_float_row portrait"
            width="420"
            height="580"
            alt="mode image 1"
          />
        </Slider>
      )}
      <SectionRecruiting recruiting={recruiting} />
      <SectionFacts columns={columns} />

      <section className="section aboutShoreSection">
        <div className="container  has-media-left">
          <div className="columns is-centered is-vcentered">
            <div className="column is-5 is-12-mobile has-text-centered">
              <ImageGallery
                name={aboutShore.image}
                alt="shore app in use"
                className="section-image image-left"
                maxWidth="1058"
                height="384"
              />
            </div>
            <div className="column is-6 is-12-mobile  ">
              <p className="text-big is-centered">{aboutShore.text}</p>
            </div>
          </div>
        </div>
      </section>
      {/*  <RepeatingSections data={sections}    /> */}

      <OpenJobs title={t('jobsTitle')} contact={contactData} />
    </Layout>
  );
};

export default Page;
